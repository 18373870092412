import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';
import FailedOrders from 'views/pages/failed-orders/FailedOrders';
import AdminRiderOrdersTab from 'views/pages/riders/admin/AdminRiderOrdersTab';
// import NotFoundPage from 'views/pages/notfound';

// dashboard routing
const Branches = Loadable(lazy(() => import('views/pages/branches/Branches')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
// const Departments = Loadable(lazy(() => import('views/pages/departments/Departments')));
const Employees = Loadable(lazy(() => import('views/pages/employees/Employees')));
const Categories = Loadable(lazy(() => import('views/pages/categories/Categories')));
const Products = Loadable(lazy(() => import('views/pages/products/Products')));
const RecentOrders = Loadable(lazy(() => import('views/pages/recent-orders/RecentOrders')));
const Discounts = Loadable(lazy(() => import('views/pages/discounts/Discounts')));
// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = isAuthenticated => ({
  path: '/admin',
  element: isAuthenticated ? <MainLayout /> : <Navigate to="/auth/signin" />,
  children: [
    {
      path: '',
      element: <DashboardDefault />,
    },
    {
      path: 'branches',
      children: [
        {
          path: '',
          element: <Branches />,
        },
      ],
    },
    {
      path: 'dashboard',
      children: [
        {
          path: '',
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: 'categories',
      children: [
        {
          path: '',
          element: <Categories />,
        },
      ],
    },
    {
      path: 'discounts',
      children: [
        {
          path: '',
          element: <Discounts />,
        },
      ],
    },
    {
      path: 'products',
      children: [
        {
          path: '',
          element: <Products />,
        },
      ],
    },
    {
      path: 'recent-orders',
      children: [
        {
          path: '',
          element: <RecentOrders />,
        },
      ],
    },
    {
      path: 'failed-orders',
      children: [
        {
          path: '',
          element: <FailedOrders />,
        },
      ],
    },
    {
      path: 'delivery-orders',
      children: [
        {
          path: '',
          element: <AdminRiderOrdersTab />,
        },
      ],
    },
    // {
    //   path: 'employees',
    //   children: [
    //     {
    //       path: '',
    //       element: <Employees />,
    //     },
    //   ],
    // },
  ],
});

export default AdminRoutes;
