import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CardActions,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Badge,
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';
import CustomTooltip from 'ui-component/CustomTooltip/CustomTooltip';
import {
  CONST_GETALL,
  CONST_MODULE_ORDERS,
  CONST_ORDER_COD,
  CONST_ORDER_STATUS_ORDER_REQUESTED,
} from 'utils/constants';
import { handleApiAction } from 'utils/apiUtils/apiAction';
import useAppContext from 'store/useAppContext';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';
import soundFile from 'assets/sounds/level-up.mp3';
import { getUserRole } from 'utils/commonFunc';

// notification status options
const status = [
  {
    value: 'all',
    label: 'All Notification',
  },
  {
    value: 'new',
    label: 'New',
  },
  {
    value: 'unread',
    label: 'Unread',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

let timer;
// ==============================|| NOTIFICATION ||============================== //
const module = CONST_MODULE_ORDERS;
const NotificationSection = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [newOrdersCount, setNewOrdersCount] = useState(0);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const {
    crudMethods,
    ordersMethods: { setOrdersState = undefined } = {},
    ordersState: { deliveryOrdersFetching, deliveryOrders = [] },
  } = useAppContext();
  const { getMethodByModule } = useStoreAccessByModule();
  const { isRoleAdmin } = getUserRole();

  const notificationSound = useMemo(() => {
    const audio = new Audio(soundFile);
    audio.loop = true;
    audio.playbackRate = 1.5; // Set playback speed to 1.5x, adjust as needed
    return audio;
  }, []);

  const getNewOrdersList = (passNewOrders = deliveryOrders) => {
    return passNewOrders?.filter(order => order.orderStatus === CONST_ORDER_STATUS_ORDER_REQUESTED);
  };

  const isPlayingCheck = useCallback(() => {
    return (
      notificationSound && notificationSound?.play && !notificationSound.ended && notificationSound.currentTime > 0
    );
  }, [notificationSound]);

  const isPlaying = useMemo(() => {
    return isPlayingCheck();
  }, [isPlayingCheck]);

  const startPlaying = passOrdersList => {
    setNewOrdersCount(passOrdersList?.length);
    if (notificationSound) {
      notificationSound.play().catch(error => {
        console.error('Audio playback failed:', error);
      });
    }
  };

  const stopPlaying = () => {
    setNewOrdersCount(0);
    notificationSound?.pause();
  };

  useEffect(() => {
    const newOrdersList = getNewOrdersList();
    if (!newOrdersList?.length) {
      stopPlaying();
    } else if (newOrdersList?.length) {
      startPlaying(newOrdersList);
    }
  }, [deliveryOrdersFetching, deliveryOrders?.length, isPlaying]);

  const commonParams = useMemo(
    () => ({
      crudMethods,
      setState: setOrdersState || getMethodByModule({ module }),
      module,
    }),
    [crudMethods, setOrdersState, getMethodByModule]
  );

  const updateOrderNotifcation = useCallback(
    currentNewOrders => {
      const newOrdersList = getNewOrdersList(currentNewOrders);
      if (newOrdersList?.length) {
        startPlaying(newOrdersList);
      } else stopPlaying();
    },
    [deliveryOrders, notificationSound]
  );

  const fetchRecentOrdersHandler = useCallback(async () => {
    const res = await handleApiAction({
      ...commonParams,
      action: CONST_GETALL,
      apiPropName: 'getInternalOrders',
      // stateParam: 'deliveryOrders',
      loadingParam: 'deliveryOrdersFetching',
      orderType: CONST_ORDER_COD,
    });
    const currentNewOrders = res.data ?? [];
    updateOrderNotifcation(currentNewOrders);
    setContextState({
      setState: setOrdersState,
      paramName: 'deliveryOrders',
      paramValue: currentNewOrders,
    });
  }, [commonParams, setOrdersState, updateOrderNotifcation]);

  const fetchRecentOrders = useCallback(() => {
    timer = setInterval(() => {
      if (!deliveryOrdersFetching) {
        fetchRecentOrdersHandler();
      }
    }, 120_000); // Fetch every 2 mins
  }, [deliveryOrdersFetching, fetchRecentOrdersHandler]);

  const fetchInitialNewOrders = useCallback(() => {
    if (!deliveryOrdersFetching) {
      handleApiAction({
        ...commonParams,
        action: CONST_GETALL,
        apiPropName: 'getInternalOrders',
        stateParam: 'deliveryOrders',
        loadingParam: 'deliveryOrdersFetching',
        orderType: CONST_ORDER_COD,
      });
    }
  }, [commonParams, deliveryOrdersFetching]);

  useEffect(() => {
    if (!deliveryOrders?.length && isRoleAdmin) {
      fetchInitialNewOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isRoleAdmin && fetchRecentOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      timer && clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRecentOrders]);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleChange = event => {
    if (event?.target.value) setValue(event?.target.value);
  };

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down('md')]: {
            mr: 2,
          },
        }}>
        <Badge
          sx={{ fontSize: 12, cursor: 'pointer', marginRight: 2 }}
          badgeContent={newOrdersCount || ''}
          color={newOrdersCount ? 'secondary' : ''} // customize based on count or theme
          anchorOrigin={{
            vertical: 'top', // position relative to icon
            horizontal: 'right',
          }}>
          <CustomTooltip content="View new orders">
            <ButtonBase sx={{ borderRadius: '12px' }}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  transition: 'all .2s ease-in-out',
                  background: theme.palette.secondary.light,
                  color: theme.palette.secondary.dark,
                  '&[aria-controls="menu-list-grow"],&:hover': {
                    background: theme.palette.secondary.dark,
                    color: theme.palette.secondary.light,
                  },
                }}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={() => {
                  // if (!pathname.includes('recent-orders')) {
                  navigate('/admin/recent-orders?tab=new_orders');
                  // }
                  // notificationSound.pause();
                  // setNewOrdersCount(false);
                }}
                color="inherit">
                <IconBell stroke={1.5} size="1.3rem" />
              </Avatar>
            </ButtonBase>
          </CustomTooltip>
        </Badge>
      </Box>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}>
        {({ TransitionProps }) => (
          <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                        <Grid item>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle1">All Notification</Typography>
                            <Chip
                              size="small"
                              label="01"
                              sx={{
                                color: theme.palette.background.default,
                                bgcolor: theme.palette.warning.dark,
                              }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item>
                          <Typography component={Link} to="#" variant="subtitle2" color="primary">
                            Mark as all read
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <PerfectScrollbar
                        style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item xs={12}>
                            <Box sx={{ px: 2, pt: 0.25 }}>
                              <TextField
                                id="outlined-select-currency-native"
                                select
                                fullWidth
                                value={value}
                                onChange={handleChange}
                                SelectProps={{
                                  native: true,
                                }}>
                                {status.map(option => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} p={0}>
                            <Divider sx={{ my: 0 }} />
                          </Grid>
                        </Grid>
                        <NotificationList />
                      </PerfectScrollbar>
                    </Grid>
                  </Grid>
                  <Divider />
                  <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                    <Button size="small" disableElevation>
                      View All
                    </Button>
                  </CardActions>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default NotificationSection;
