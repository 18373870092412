import React from 'react';
import SockJsClient from 'react-stomp';

const MapSocketConnection = ({ registerSocket, handleLocationReceivedFromRider, socketRef }) => {
  const onConnectCallback = () => {
    console.info('Connected to WebSocket');
    if (socketRef?.current) registerSocket(socketRef);
  };

  return (
    <SockJsClient
      url={`${process.env.REACT_APP_API_BASE_DOMAIN_URL}/ws`}
      topics={['/topic/restaurant-web-socket']}
      onMessage={handleLocationReceivedFromRider}
      ref={client => {
        if (client) {
          socketRef.current = client;
        }
      }}
      onConnect={onConnectCallback}
      onDisconnect={() => console.info('Disconnected from WebSocket')}
    />
  );
};

export default MapSocketConnection;
