import React, { useMemo } from 'react';
import Button from '@mui/material/Button';
import CustomLoader from '../CustomLoader/CustomLoader';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import {
  CONST_APP_CONFIRM_MODAL,
  CONST_APP_CONFIRM_MODAL_HANDLE,
  CONST_COLOR_BUTTON,
  CONST_DELETE,
} from 'utils/constants';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';
import useAppContext from 'store/useAppContext';

const CustomButton = ({
  size = 'medium',
  type = 'button',
  disabled = false,
  handleClick = undefined,
  style = null,
  variant = 'contained',
  color = CONST_COLOR_BUTTON,
  buttonContent = null,
  name = 'Save',
  className = '',
  fullWidth = false,
  showLoader = false,
  colorSame = false,
  onClick = undefined,
  module = '',
  isActionPostOrPut = false,
  loadingParam = '',
  passLoading = false,
  isEdit = false,
  startIcon = null,
  endIcon = null,
  sx = null,
  // action
  action = '', // Usally 'delete'
  deleteId = 0,
  deleteItem,
  deleting = false,
}) => {
  const {
    appMethods: { setAppState },
  } = useAppContext() ?? {};
  const { getStateParamDataByModule } = useStoreAccessByModule();

  const loading = useMemo(() => {
    if (passLoading) return passLoading;
    else if (isActionPostOrPut) {
      return ['posting', 'putting', ...(loadingParam ? [loadingParam] : [])].some(
        param =>
          !!getStateParamDataByModule({
            module,
            passStateParamName: param,
          })
      );
    } else if (loadingParam && typeof loadingParam === 'string') {
      return !!getStateParamDataByModule({
        module,
        passStateParamName: loadingParam,
      });
    } else if (action === CONST_DELETE) {
      return deleting;
    } else {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActionPostOrPut, loadingParam, module, passLoading, action, deleting]);

  const updateContext = (paramName, paramValue) => {
    setContextState({
      setState: setAppState,
      paramName,
      paramValue,
    });
  };

  const actionClick = () => {
    updateContext(CONST_APP_CONFIRM_MODAL, true);
    updateContext(CONST_APP_CONFIRM_MODAL_HANDLE, () => deleteItem(deleteId, true));
  };

  return (
    <Button
      fullWidth={fullWidth}
      size={size}
      className={className}
      type={type}
      color={colorSame ? color : name.toLowerCase() === 'cancel' ? 'inherit' : color}
      style={{ fontWeight: 'bold', ...style }}
      variant={variant}
      disabled={loading || disabled}
      onClick={action === CONST_DELETE ? actionClick : handleClick || onClick}
      startIcon={
        (!loading && startIcon) ||
        ((type === 'submit' || showLoader) && !!loading && <CustomLoader size={17} color="inherit" />)
      }
      endIcon={!loading && endIcon}
      sx={sx}>
      <span>{isEdit ? 'Update' : buttonContent || name}</span>
    </Button>
  );
};

export default CustomButton;
