import { isObject } from 'formik';
import { CONST_TYPE_TEXT, CONST_FIELD_INPUT } from '../constants';

const formOptions = {
  md: 12,
  isReq: false,
  validations: [],
  validationError: '',
  isField: true,
};

// fieldType: INPUT, RADIO, DATE_PICKER, SELECT, MULTI_SELECT, CHECKBOX
const formObjBuild = formProps => {
  const {
    fieldLabel = '',
    fieldName = '',
    fieldValue = '',
    fieldType = CONST_FIELD_INPUT,
    type = CONST_TYPE_TEXT,
    options = {},
  } = formProps;
  if (options?.isNotField) {
    return { fieldName, fieldValue };
  }
  return {
    fieldLabel,
    fieldType,
    fieldName,
    fieldValue,
    options: { ...formOptions, ...options },
    type,
  };
};

const formStateByData = (passData, initialState) => {
  const formDataList = Object.values(initialState).map(el => {
    return {
      ...el,
      fieldValue: passData?.[el?.fieldName],
    };
  });
  const formObj = {};
  formDataList.forEach(formEl => {
    formObj[formEl.fieldName] = formEl;
  });
  return formObj;
};

// e.g labelKeyObj: { key1: 'discountName', key2: 'discountPercent', key2Append: '%', separator: '-' }
// e.g valObj: { discountName: 'first-discount', discountName: 12.4 }
// e.g response: 'first-discount - 12.4 %'
// const formLabel = (valObj, labelKeyObj) => {
//   const { key1, key2, key2Append } = labelKeyObj;

//   // Extract values from the valObj based on keys
//   const firstKeyVal = valObj[key1];
//   const secondKeyVal = valObj[key2];

//   // Construct the label string
//   let label = `${firstKeyVal} (${secondKeyVal}${key2Append})`;
//   return label;
// };

const formDataForSelect = (data, keys) => {
  if (data?.length && keys?.length) {
    return data.map(item => {
      return {
        label: item[keys[0]],
        value: item[keys[1]],
      };
    });
  }
  return [];
};

export { formObjBuild, formStateByData, formDataForSelect };
