import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  ButtonBase,
  // LinearProgress
} from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import useAppContext from 'store/useAppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOrgId, isUserAdmin } from 'utils/commonFunc';
import { useEffect } from 'react';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const leftDrawerOpened = useSelector(state => state?.customization?.opened);
  const pathname = location?.pathname;
  const isAdmin = isUserAdmin();
  const {
    cartsState: { cartsData },
  } = useAppContext();
  const isPathBranches = pathname === '/admin/branches';
  const orgId = getOrgId();

  useEffect(() => {
    if (leftDrawerOpened) {
      handleLeftDrawerToggle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto',
          },
        }}>
        {!isPathBranches && (
          <ButtonBase sx={{ marginRight: 1, borderRadius: '12px', overflow: 'hidden' }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                '&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light,
                },
              }}
              onClick={() => !isPathBranches && handleLeftDrawerToggle()}
              color="inherit">
              <IconMenu2 stroke={1.5} size="1.3rem" />
            </Avatar>
          </ButtonBase>
        )}
        <Box
          onClick={() => !isPathBranches && navigate('/branch-info')}
          component="span"
          sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1, alignSelf: 'center' }}>
          <LogoSection isPathBranches={isPathBranches} />
        </Box>
      </Box>
      {isAdmin && ((isPathBranches && orgId) || !isPathBranches) && (
        <CustomButton
          size="small"
          name={isPathBranches ? 'Go to dashboard' : 'Change branch'}
          variant="outlined"
          sx={{ py: 0, px: 1 }}
          onClick={() => {
            if (isPathBranches) {
              navigate('/admin/dashboard');
            } else {
              navigate('/admin/branches');
            }
          }}
          startIcon={isPathBranches ? <ArrowCircleLeftIcon fontSize="small" /> : <CheckCircleIcon fontSize="small" />}
        />
      )}

      {/* header search */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      {!isAdmin && (
        <Badge
          sx={{ fontSize: 12, cursor: 'pointer', marginRight: 2 }}
          badgeContent={['/view-cart', '/view-checkout'].includes(pathname) ? 0 : cartsData?.length}
          color="secondary" // customize based on count or theme
          anchorOrigin={{
            vertical: 'top', // position relative to icon
            horizontal: 'right',
          }}
          onClick={() => !isPathBranches && navigate('/view-cart')}>
          <ShoppingCart sx={{ fontSize: '25px' }} />
        </Badge>
      )}
      {/* notification & profile */}
      {isAdmin && !isPathBranches && <NotificationSection />}
      <ProfileSection isPathBranches={isPathBranches} />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
