import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import authState from './states/authState';
import employeesState from './states/employeesState';
import useAuthMethodHandler from './handlers/useAuthMethodHandler';
import { CONST_LOCAL_STORAGE_LOGGED_USER, CONST_LOGGED_USER } from 'utils/constants';
import { getLocalStorage } from 'utils/commonFunc';
import { setContextState } from '../../utils/contextStoreUtils/setContextUtils';
import useCrudMethodHandler from './handlers/useCrudMethodHandler';
import appState from './states/appState';
import categoriesState from './states/categoriesState';
import productsState from './states/productsState';
import cartsState from './states/cartsState';
import addressesState from './states/addressesState';
import ordersState from './states/ordersState';
import dashboardsState from './states/dashboardsState';
import branchesState from './states/branchesState';
import discountsState from './states/discountsState';
import paymentsState from './states/payments';

export const initialAppNotificationState = {
  status: undefined,
  data: undefined,
};
// Define a context
export const AppContext = createContext({
  // App
  appNotification: initialAppNotificationState,
  setAppNotification: undefined,
  // Auth
  authState,
  authMethods: undefined,
  //** Crud */
  crudMethods: undefined,
  //** Employees */
  employeesState,
  employeesMethods: undefined,
  // Categories
  categoriesState,
  categoriesMethods: undefined,
  // Discount
  discountsState,
  discountsMethods: undefined,
  // Products
  productsState,
  productsMethods: undefined,
  // Carts
  cartsState,
  cartsMethods: undefined,
  // Addresses
  addressesState,
  addressesMethods: undefined,
  // Order
  ordersState,
  ordersMethods: undefined,
  // Branch
  branchesState,
  branchesMethods: undefined,
  // Payment
  paymentsState,
  paymentsMethods: undefined,
});

// Define a component that provides data to the context
const AppProvider = ({ children }) => {
  const [appNotification, setAppNotification] = useState(initialAppNotificationState);
  const [authLocalState, setAuthState] = useState(authState);
  // Employee
  const [employeesLocalState, setEmployeesState] = useState(employeesState);
  // Department
  const [appLocalState, setAppState] = useState(appState);
  const [categoriesLocalState, setCategoriesState] = useState(categoriesState);
  const [discountsLocalState, setDiscountsState] = useState(discountsState);
  const [productsLocalState, setProductsState] = useState(productsState);
  const [cartsLocalState, setCartsState] = useState(cartsState);
  const [addressesLocalState, setAddressesState] = useState(addressesState);
  const [ordersLocalState, setOrdersState] = useState(ordersState);
  const [dashboardsLocalState, setDashboardsState] = useState(dashboardsState);
  const [branchesLocalState, setBranchesState] = useState(branchesState);
  const [paymentsLocalState, setPaymentsState] = useState(paymentsState);

  useEffect(() => {
    if (!authLocalState?.loggedUser?.userToken) {
      const user = getLocalStorage(CONST_LOCAL_STORAGE_LOGGED_USER);
      if (user?.userId) {
        setContextState({
          setState: setAuthState,
          paramValue: {
            [CONST_LOGGED_USER]: user,
            isAuthenticated: true,
          },
        });
      } else {
        setContextState({
          setState: setAuthState,
          paramValue: {
            [CONST_LOGGED_USER]: {},
            isAuthenticated: false,
          },
        });
      }
    }
  }, [authLocalState?.loggedUser?.userToken]);

  const contextState = {
    appState: appLocalState,
    setAppState,
    appNotification,
    setAppNotification,
    authState: authLocalState,
    setAuthState,
  };

  const authMethods = useAuthMethodHandler({ contextState });

  const crudMethods = useCrudMethodHandler({ contextState });

  const contextValue = {
    //** App */
    appNotification,
    setAppNotification,
    appState: appLocalState,
    appMethods: { setAppState },
    //** Auth */
    authState: authLocalState,
    authMethods: { ...authMethods, setAuthState },
    //** Crud */
    crudMethods,
    //** Employees */
    employeesState: employeesLocalState,
    employeesMethods: { setEmployeesState },
    // Categories
    categoriesState: categoriesLocalState,
    categoriesMethods: { setCategoriesState },
    // Categories
    discountsState: discountsLocalState,
    discountsMethods: { setDiscountsState },
    // Products
    productsState: productsLocalState,
    productsMethods: { setProductsState },
    // Carts
    cartsState: cartsLocalState,
    cartsMethods: { setCartsState },
    // Addresses
    addressesState: addressesLocalState,
    addressesMethods: { setAddressesState },
    // Order
    ordersState: ordersLocalState,
    ordersMethods: { setOrdersState },
    // Dashboard
    dashboardsState: dashboardsLocalState,
    dashboardsMethods: { setDashboardsState },
    // Branches
    branchesState: branchesLocalState,
    branchesMethods: { setBranchesState },
    // Payments
    paymentsState: paymentsLocalState,
    paymentsMethods: { setPaymentsState },
  };
  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.node?.isRequired,
};

export default AppProvider;
