import React, { useState, useEffect, useRef, useMemo } from 'react';
import { APIProvider, AdvancedMarker, InfoWindow, Map } from '@vis.gl/react-google-maps';
import { CONST_LOCAL_STORAGE_BRANCH } from 'utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { getLocalStorage, getLocalUser, getUserRole, setLocalStorage } from 'utils/commonFunc';
import MapDirections from './MapDirections';
import MapSocketConnection from './MapSocketConnection';
import { Box } from '@mui/material';
import BlinkCircle from 'ui-component/icons/BlinkCircle';
import PersonPinIcon from '@mui/icons-material/PersonPin';

let intervalTimer;
let watchId;
const TrackOrder = () => {
  const navigate = useNavigate();
  const { orderSessionId, toLat = 0, toLng = 0 } = useParams();
  const branchInfo = getLocalStorage(CONST_LOCAL_STORAGE_BRANCH);
  const { latitude = 0, longitude = 0 } = branchInfo ?? {};
  const center = { lat: Number(latitude), lng: Number(longitude) };
  const user = getLocalUser();
  const { isRoleRider } = getUserRole();
  const socketRef = useRef(null);

  const endCoords = useMemo(() => ({ lat: Number(toLat), lng: Number(toLng) }), [toLat, toLng]);

  const [currentCoords, setCurrentCoords] = useState(null);

  const [isRiderInfoOpen, setRiderInfoOpen] = useState(false);
  const [isHomeInfoOpen, setHomeInfoOpen] = useState(false);

  const defaultZoom = 10;
  const [myZoom, setMyZoom] = useState(null);
  const [myCenter, setMyCenter] = useState(null);

  // Web socket
  const registerSocket = () => {
    if (socketRef.current) {
      socketRef.current?.sendMessage(
        '/app/chat.connectRestaurantWebSocket',
        JSON.stringify({
          type: 'JOIN',
          sender: user.userId,
        })
      );
    }
  };

  useEffect(() => {
    if ((!orderSessionId || !toLat || !toLng) && isRoleRider) {
      navigate('/rider/delivery-orders?tab=assigned');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoleRider, toLat, toLng]);

  const updateLocationToCustomer = (lat, lng) => {
    if (lat && lng && socketRef.current) {
      const locDto = {
        type: 'GPS_LOC',
        sender: user?.userId,
        locationDto: {
          latitude: lat,
          longitude: lng,
          locationId: 0,
          imeiNo: '1',
        },
      };
      socketRef.current?.sendMessage('/app/chat.sendRestaurantWebSocket', JSON.stringify(locDto));
    }
  };

  const updateCoords = position => {
    const { latitude: lat, longitude: lng } = position?.coords ?? {};
    if (lat && lng && isRoleRider) {
      updateLocationToCustomer(lat, lng);
    }
  };

  useEffect(() => {
    if (isRoleRider) {
      navigator.geolocation.getCurrentPosition(updateCoords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isRoleRider) {
      navigator.geolocation.getCurrentPosition(updateCoords);
      intervalTimer = setInterval(() => {
        watchId = navigator.geolocation.getCurrentPosition(updateCoords);
      }, 15_000);
    }
    return () => {
      clearInterval(intervalTimer);
      navigator.geolocation.clearWatch(watchId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoleRider]);

  const handleLocationReceivedFromRider = (locationData, topic) => {
    const { latitude: lat, longitude: lng } = locationData?.locationDto ?? {};
    if (lat && lng) {
      setCurrentCoords({ lat, lng });
      orderSessionId && setLocalStorage(orderSessionId, { lat, lng });
    }
  };

  const lastRiderLatLng = getLocalStorage(orderSessionId);
  const liveCoords = currentCoords?.lat ? currentCoords : lastRiderLatLng;
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <APIProvider
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={['maps', 'marker', 'places', 'routes', 'streetView']}>
        <Map
          disableDefaultUI={false}
          style={{ width: '100%', height: '100%' }}
          defaultZoom={defaultZoom}
          defaultCenter={currentCoords?.lat ? currentCoords : center}
          center={myCenter}
          zoom={myZoom}
          mapId="RES_MAP_ID"
          onZoomChanged={gmap => {
            const newZoom = gmap.detail.zoom;
            setMyZoom(newZoom);
          }}
          onCenterChanged={gmap => {
            const newCenter = gmap.detail.center;
            setMyCenter(newCenter);
          }}>
          <AdvancedMarker
            key="rider"
            position={liveCoords}
            // onClick={() => setRiderInfoOpen(true)}
          >
            <BlinkCircle />
            {isRiderInfoOpen && (
              <InfoWindow position={currentCoords} onCloseClick={() => setRiderInfoOpen(false)}>
                <div>Rider's Current Location</div>
              </InfoWindow>
            )}
          </AdvancedMarker>
          <AdvancedMarker
            key="home"
            position={endCoords}
            // onClick={() => setHomeInfoOpen(true)}
          >
            <PersonPinIcon fontSize="large" style={{ color: 'red' }} />
            {isHomeInfoOpen && (
              <InfoWindow position={endCoords} onCloseClick={() => setHomeInfoOpen(false)}>
                <div>Delivery Destination</div>
              </InfoWindow>
            )}
          </AdvancedMarker>
          {!!liveCoords?.lat && (
            <MapDirections currentCoords={liveCoords} endCoords={endCoords} myCenter={myCenter} myZoom={myZoom} />
          )}
          {!liveCoords?.lat && (
            <InfoWindow position={endCoords} headerDisabled>
              <Box sx={{ py: 1 }}>Loading...</Box>
              <Box>Waiting for the rider's location to show route direction.</Box>
            </InfoWindow>
          )}
        </Map>
      </APIProvider>
      <MapSocketConnection
        socketRef={socketRef}
        registerSocket={registerSocket}
        handleLocationReceivedFromRider={handleLocationReceivedFromRider}
      />
    </div>
  );
};

export default TrackOrder;
